<template>
    <div class="login_container">
        <div class="go_back" @click="$router.replace('/login')">
            <Back class="icon" />
        </div>
        <div class="page_name">
            <p>找回密码</p>
        </div>
        <div class="form_box">
            <div class="tab_row">
                <div class="tab_item" :class="backType === 'mail' ? 'active' : ''" @click="backType = 'mail'">邮箱找回</div>
                <div class="tab_item" :class="backType === 'phone' ? 'active' : ''" @click="backType = 'phone'">手机找回</div>
            </div>
            <el-form :model="formData">
                <ul>
                    <el-form-item v-if="backType === 'mail'">
                        <li>
                            <input type="email" :maxlength="50" v-model.trim="formData.email" id="mail" />
                            <label class="label_name" :class="formData.email ? 'small' : ''" for="mail">邮箱</label>
                        </li>
                    </el-form-item>
                    <el-form-item v-if="backType === 'phone'">
                        <li>
                            <input type="tel" :maxlength="11" v-model.trim="formData.phone" id="phone" />
                            <label class="label_name" :class="formData.phone ? 'small' : ''" for="phone">手机号</label>
                        </li>
                    </el-form-item>
                    <el-form-item>
                        <li>
                            <input :maxlength="6" v-model.trim="formData.code" id="code" />
                            <label class="label_name" :class="formData.code ? 'small' : ''" for="code">验证码</label>
                            <el-button class="btn" :disabled="disableTime > 0" @click="sendCode">
                                <template #default v-if="disableTime > 0">{{ disableTime }}秒</template>
                                <template #default v-else>发送验证码</template>
                            </el-button>
                        </li>
                    </el-form-item>
                </ul>
            </el-form>
            <div class="btn_row">
                <button class="btn" @click="handleSubmit">提交</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import { Back } from '@element-plus/icons-vue';
import { sendMessageEmail, sendMessageSms, apiUserFindPwd } from '@/request/api';
import { ElMessage } from 'element-plus';
import { validatePhone, validateEmail } from '@/utils/validate';
import withLoading from '@/utils/loading';
import { useRouter } from 'vue-router';

const $router = useRouter();

const backType = ref('mail');// 找回类型 mail | phone
const formData = reactive({
    email: '',
    phone: '',
    code: ''
})
// 发送验证码禁用计时
const disableTime = ref(0);

// 提交数据
const formDataComputed = computed(() => {
    const res = {};
    if (backType.value === 'mail') {
        res['email'] = formData.email;
        res['emailCode'] = formData.code;
    }
    if (backType.value === 'phone') {
        res['phone'] = formData.phone;
        res['phoneCode'] = formData.code;
    }
    return res;
})

// 开始禁用计时
function startDisableTime(duration) {
    disableTime.value = duration;
    const t = setInterval(() => {
        disableTime.value -= 1;
        if (disableTime.value === 0) {
            clearInterval(t);
        }
    }, 1000);
}

// 发送验证码
function sendCode() {
    if (disableTime.value > 0) return;
    if (backType.value === 'mail') {
        if (validateEmail(formData.email)) {
            sendMail();
        } else {
            ElMessage.error('请输入正确的邮箱');
            return;
        }
    }
    if (backType.value === 'phone') {
        if (validatePhone(formData.phone)) {
            sendPhone();
        } else {
            ElMessage.error('请输入正确的手机号');
            return;
        }
    }
}

// 发送邮箱
async function sendMail() {
    const { code } = await withLoading(sendMessageEmail, { text: '发送中...' })({ email: formData.email });
    if (code !== '0') return;
    startDisableTime(60);// 开始倒计时
    ElMessage.success('已发送，请注意查收');
}

// 发送手机
async function sendPhone() {
    const { code } = await withLoading(sendMessageSms, { text: '发送中...' })({ phone: formData.phone, type: 1 });
    if (code !== '0') return;
    startDisableTime(60);// 开始倒计时
    ElMessage.success('已发送，请注意查收');
}

// 点击提交
function handleSubmit() {
    if (backType.value === 'mail' && !validateEmail(formData.email)) {
        ElMessage.error('请输入正确的邮箱');
        return;
    }
    if (backType.value === 'phone' && !validatePhone(formData.phone)) {
        ElMessage.error('请输入正确的手机号');
        return;
    }
    if (!formData.code) {
        ElMessage.error('请输入验证码');
        return;
    }
    findPwd();
}

async function findPwd() {
    const { code } = await withLoading(apiUserFindPwd)(formDataComputed.value);
    if (code !== '0') return;
    ElMessage.success('新密码已发送，请注意查收');
    $router.replace('/login/index');
}

</script>

<style lang="scss" scoped>
.login_container {
    height: 100%;
    padding-top: 80px;

    .go_back {
        width: 88px;
        height: 96px;
        background-color: var(--primary-color);
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        clip-path: circle(60px at 100% 15px);
        padding-left: 50px;
        padding-top: 15px;

        .icon {
            width: 2em;
            height: 2em;
            color: #fff;
        }
    }

    .page_name {
        text-align: center;

        p {
            color: #292D38;
            font-size: 36px;
        }
    }

    .tab_row {
        display: flex;
        border-bottom: 1px solid #D9D9D9;
        margin-bottom: 30px;

        .tab_item {
            font-size: 16px;
            line-height: 50px;
            color: #454954;
            margin-right: 56px;
            position: relative;
            user-select: none;

            &:not(.active) {
                cursor: pointer;

            }

            &.active {
                color: var(--primary-color);

                &::after {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: var(--primary-color);
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                }
            }

        }
    }

    .form_box {
        padding: 0 66px;
        padding-top: 40px;

        ul {
            li {
                position: relative;
                border-bottom: 1px solid #d8d8d8;
                height: 66px;
                padding-top: 44px;

                .label_name {
                    position: absolute;
                    left: 5px;
                    top: 30px;
                    font-size: 16px;
                    color: #070A16;
                    transition: all .3s linear;
                    cursor: text;

                    &.small {
                        top: 0;
                        left: 0;
                        color: #999;
                        font-size: 14px;
                    }
                }

                input {
                    width: 100%;
                    height: 50px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background: none;
                    border: none;
                    font-size: 16px;
                    padding: 0 5px;

                    &:focus {

                        &+.label_name {
                            top: 0;
                            left: 0;
                            color: #999;
                            font-size: 14px;
                        }
                    }
                }

                .btn {
                    width: 120px;
                    height: 40px;
                    border-radius: 8px;
                    background-color: #D9D9D9;
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    bottom: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #070A16;

                    &:hover {
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }

        .btn_row {
            margin-top: 30px;

            .btn {
                width: 100%;
                height: 48px;
                line-height: 48px;
                border-radius: 8px;
                background: linear-gradient(-63.43deg, rgba(0, 149, 212, 1) 0%, rgba(0, 92, 169, 1) 100%);
                color: #fff;
                font-size: 18px;
                letter-spacing: 5px;
            }
        }
    }
}
</style>